<template>
  <v-container align="center" fluid justify="center">
    <v-col>
      <v-row class="d-flex justify-center">
        <v-col cols="12" class="d-flex justify-center pb-0 pt-0">
          <v-card :loading="loading" width="850" elevation="3" id="dataapp">
            <v-card-title class="ml-10"> </v-card-title>
            <v-card-text>
              <v-row class="ml-10" style="border-bottom: 1px groove">
                <v-col cols="9" class="mt-7">
                  <v-row class="d-flex flex-column">
                    <v-col class="pt-0">
                      <v-text style="font-size: 2.1rem; color: #757575">
                        INVOICE
                      </v-text>
                    </v-col>
                    <v-col class="py-0"
                      ><p class="font-weight-medium py-0 mb-0">
                        BILL FROM :
                      </p></v-col
                    >
                    <v-col class="pb-0"
                      ><p class="mb-0 text-body-1">
                        {{ companyinfo.name }}
                      </p></v-col
                    >
                    <v-col cols="4" class="py-0">
                      <p class="text-body-1 mb-0">
                        {{ companyinfo.address }}
                      </p>
                    </v-col>
                  </v-row>
                </v-col>

                <v-col cols="2" align-self="center">
                  <div style="height: 70%; width: 70%">
                    <v-img
                      :src="companyinfo.logo_url"
                      alt="Rooster crowing"
                    ></v-img>
                  </div>
                </v-col>
              </v-row>
              <div>
                <v-row class="ml-10 pa-0 mt-5">
                  <v-col class="py-0 mb-0" cols="8"
                    ><p class="mb-0 font-weight-medium">BILL TO :</p></v-col
                  >
                  <v-col cols="4" class="py-0 d-flex flex-row">
                    <p class="text-body-1 mb-0">INVOICE #</p>
                    <v-spacer></v-spacer>
                    <p class="text-body-1 mb-0 mr-13">{{ invoiceinfo.id }}</p>
                  </v-col>

                  <v-col cols="8" class="py-0">
                    <p class="text-body-1 mb-0 mt-2">
                      {{ userdata.name }}
                    </p>
                  </v-col>
                  <v-col cols="4" class="py-0 d-flex flex-row">
                    <v-col class="py-0 d-flex flex-row pl-0 mr-5">
                      <p class="text-body-1 mb-0">Date:</p>
                      <v-spacer></v-spacer>
                      <p class="text-body-1 mb-0 mr-5">
                        {{ invoiceinfo.date }}
                      </p>
                    </v-col>
                  </v-col>
                  <v-col cols="8" class="py-0">
                    <p class="text-body-1 mb-0">
                      {{ userdata.phone_number }}
                    </p>
                    <p class="text-body-1 mb-0">{{ userdata.address }}</p>
                    <p class="text-body-1 mb-0">{{ userdata.location }}</p>
                  </v-col>
                  <v-col
                    cols="4"
                    v-if="orderinfo.order_status == 'Unpaid'"
                    class="py-0"
                    dense
                  >
                    <div
                      class="d-flex flex-row align-center mr-12"
                      style="background-color: #cfd3d4"
                    >
                      <p class="mt-2 mb-1 font-weight-medium ml-2 mb-2">
                        Amount Due:
                      </p>
                      <v-spacer></v-spacer>
                      <p class="mt-2 mb-1 font-weight-medium mr-5">
                        {{ orderinfo.due }}
                      </p>
                    </div>
                    <div>
                      <p
                        style="font-family: 'PT Serif', serif; bold; font-size:30px;"
                        class="mt-3 mb-2 font-weight-bold"
                      >
                        UNPAID
                      </p>
                    </div>
                  </v-col>
                  <v-col
                    cols="4"
                    v-if="orderinfo.order_status == 'Paid'"
                    class="py-0"
                  >
                    <p
                      style="font-family: 'PT Serif', serif; font-size: 30px"
                      class="mt-2 mb-0 font-weight-bold"
                    >
                      PAID
                    </p>
                  </v-col>
                </v-row>
                <v-row> </v-row>
                <v-card min-height="500" elevation="0" class="mt-2">
                  <v-data-table
                    :headers="headers"
                    :items="orders"
                    item-key="id"
                    class="elevation-0 mx-12"
                    hide-default-footer
                    dense
                  >
                    <template v-slot:item.actions="{ item }">
                      <v-menu offset-y>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            v-bind="attrs"
                            v-on="on"
                            class="mr-2"
                            @click="viewoption(item)"
                            >mdi-pencil</v-icon
                          >
                        </template>
                        <v-list>
                          <v-list-item>
                            <v-btn
                              color="primary"
                              dark
                              @click="Return(item)"
                              text
                            >
                              Return
                            </v-btn>
                          </v-list-item>
                          <v-list-item>
                            <v-btn
                              color="primary"
                              dark
                              @click="Damage(item)"
                              text
                            >
                              Damage
                            </v-btn>
                          </v-list-item>
                          <v-list-item>
                            <v-btn
                              color="primary"
                              dark
                              @click="CancelOrder(item)"
                              text
                            >
                              Cancel
                            </v-btn>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </template>
                  </v-data-table>

                  <v-row>
                    <v-col cols="7"> </v-col>

                    <v-col cols="4" class="pr-0">
                      <div>
                        <div class="d-flex flex-row align-center pr-5">
                          <p class="mb-1 text-body-1 ml-2">Sub Total</p>
                          <v-spacer></v-spacer>
                          <p class="mb-1 text-body-1 mr-0 mb-0">
                            {{ orderinfo.sub_price }}
                          </p>
                        </div>
                        <div class="d-flex flex-row align-center pr-5">
                          <p class="text-body-1 ml-2 mb-0">Discount</p>
                          <v-spacer></v-spacer>
                          <p class="mr-0 text-body-1 mb-0">
                            {{ orderinfo.pos_additional_discount }}
                          </p>
                        </div>
                        <div class="d-flex flex-row align-center pr-5">
                          <p class="text-body-1 ml-2">VAT</p>
                          <v-spacer></v-spacer>
                          <p class="text-body-1 mr-0">
                            {{ orderinfo.vat }}
                          </p>
                        </div>
                        <div
                          class="d-flex flex-row align-center pr-5"
                          style="background-color: #cfd3d4"
                        >
                          <p class="mt-2 mb-1 font-weight-medium ml-2 mb-2">
                            Grand Total
                          </p>
                          <v-spacer></v-spacer>
                          <p class="mt-2 mb-1 font-weight-medium mr-0">
                            {{ orderinfo.price_total }}
                          </p>
                        </div>
                      </div>
                    </v-col>
                  </v-row>

                  <v-card v-if="orderinfo.order_status == 'Paid'" elevation="0">
                    <div>
                      <div style="border: 1px groove" class="mx-12">
                        <v-row>
                          <v-row class="ml-5">
                            <v-col class="" cols="3"> Transaction Date </v-col>
                            <v-col cols="3"> GateWay</v-col>
                            <v-col cols="3"> Transaction ID </v-col>
                            <v-col cols="3"> Amount</v-col>
                          </v-row>
                        </v-row>
                      </div>
                      <div style="border: 1px groove" class="mx-12">
                        <v-row>
                          <v-row class="ml-5">
                            <v-col class="" cols="3">
                              <p
                                class="text-body-1 mb-0"
                                v-if="orderinfo.ordered_date"
                              >
                                {{ orderinfo.ordered_date }}
                              </p>
                            </v-col>
                            <v-col cols="3" class="text-body-1 mb-0">
                              N/A</v-col
                            >
                            <v-col cols="3" class="text-body-1 mb-0">
                              N/A
                            </v-col>
                            <v-col cols="3">
                              <p
                                v-if="orderinfo.grand_total"
                                class="text-body-1 mb-0"
                              >
                                {{ orderinfo.grand_total }}
                              </p>
                            </v-col>
                          </v-row>
                        </v-row>
                      </div>
                    </div>
                  </v-card>
                </v-card>

                <v-row class="ml-10 d-flex flex-row">
                  <v-col cols="12">
                    <p class="font-weight-medium"></p>
                    <p class="text-caption">
                      This Invoice Is Autogenareted By
                      {{ companyinfo.name }} After Confirming Your Order And
                      Payment
                    </p>
                  </v-col>
                </v-row>
              </div>
            </v-card-text>
            <v-dialog v-model="addquantitydialog" width="750px" scrollable>
              <v-card>
                <v-toolbar color="primary" dark flat>
                  <v-toolbar-title class="mx-3">
                    Quantity: {{ specification.quantity }}</v-toolbar-title
                  >

                  <v-toolbar-title class="mx-3">
                    Remain:
                    {{
                      specification.quantity -
                      (Number(totalSubtractCountOfWarhouse) +
                        Number(totalSubtractCountOfShop))
                    }}
                    <span
                      class="red--text font-weight-medium ml-4"
                      style="border 1px white"
                      v-if="
                        specification.quantity -
                          (Number(totalSubtractCountOfWarhouse) +
                            Number(totalSubtractCountOfShop)) <
                        0
                      "
                      >Limit crossed</span
                    ></v-toolbar-title
                  >

                  <v-spacer></v-spacer>

                  <v-btn
                    color="white"
                    elevation="0"
                    small
                    @click="confirmquantity"
                    :disabled="
                      specification.quantity !=
                      Number(totalSubtractCountOfWarhouse) +
                        Number(totalSubtractCountOfShop)
                    "
                  >
                    <p class="green--text mb-0 font-weight-medium text-body-2">
                      Confirm
                    </p>
                  </v-btn>
                </v-toolbar>

                <v-divider></v-divider>
                <v-card-title class="py-2 px-0 font-weight-bold"
                  >Warehouse</v-card-title
                >
                <v-divider></v-divider>
                <v-simple-table fixed-header height="300px">
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">Name</th>
                        <th class="text-center">Quantity</th>
                        <th class="text-center">Subtract</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item, index) in warehouse" :key="index">
                        <td>{{ item.warehouse_name }}</td>
                        <td class="text-center">{{ item.added_quantity }}</td>
                        <td class="d-flex justify-center">
                          <v-form lazy-validation>
                            <v-text-field
                              outlined
                              type="number"
                              style="max-width: 100px; height: 50px"
                              class="mt-1"
                              flat
                              dense
                              v-model="item.quantity"
                              @input="warhouseSubtract(item)"
                            ></v-text-field>
                          </v-form>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>

                <v-divider></v-divider>
                <v-card-title
                  class="py-2 px-0 font-weight-bold"
                  style="background-color: #4caf50"
                  >Shop</v-card-title
                >
                <v-divider></v-divider>

                <v-simple-table fixed-header height="300px">
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">Name</th>
                        <th class="text-center">Quantity</th>
                        <th class="text-center">Subtract</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item, index) in shop" :key="index">
                        <td>{{ item.shop_name }}</td>
                        <td class="text-center">{{ item.added_quantity }}</td>
                        <td class="d-flex justify-center">
                          <v-text-field
                            outlined
                            type="number"
                            style="max-width: 100px; height: 50px"
                            class="mt-1"
                            flat
                            dense
                            v-model="item.quantity"
                            @input="shopSubtract(item)"
                          ></v-text-field>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-card>
            </v-dialog>
          </v-card>
        </v-col>
        <v-col cols="12" class="d-flex justify-center py-0">
          <v-card elevation="0" class="mt-0">
            <v-btn
              text
              @click="printData"
              class="pa-0"
              style="padding-left: 5px !important"
            >
              <v-icon class="pr-1" large color="black">mdi-printer </v-icon>
              Print
            </v-btn>
            <v-btn
              text
              @click="printData"
              style="
                padding-left: 0px !important;
                padding-right: 10px !important;
              "
            >
              <v-icon class="pr-0" large color="black">mdi-download </v-icon>
              download
            </v-btn>
          </v-card>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12" class="d-flex justify-center mb-16" v-if="note_created">
      <v-col class="pt-0 d-flex justify-center">
        <v-card class="pa-5" max-width="850">
          <p class="mb-0" style="font-size: 2.1rem; color: #757575">Details</p>
          <p class="text-justify">
            {{ current_note.content }}
          </p>
          <hr />
          <v-card-text v-if="current_note.replies.length">
            <div v-for="(reply, index) in current_note.replies" :key="index">
              <div
                style="width: 100%"
                class="d-flex justify-end mt-10"
                v-if="reply.crm_user"
              >
                <v-row
                  style="
                    max-width: 95%;
                    border: 1px solid #17a2b8;
                    border-radius: 5px;
                  "
                >
                  <v-col>
                    <p class="black--text text-right text-subtitle-2">
                      User Name
                    </p>
                    <p class="text-justify black--text mb-0">
                      {{ reply.content }}
                    </p>
                  </v-col>
                  <v-col cols="1">
                    <v-img
                      lazy-src="@/assets/helpline.svg"
                      max-height="200"
                      max-width="200"
                      :src="
                        reply.profile_picture
                          ? reply.profile_picture
                          : require(`@/assets/helpline.svg`)
                      "
                    ></v-img>
                  </v-col>
                </v-row>
              </div>
              <div
                style="width: 100%"
                class="d-flex justify-start mt-10"
                v-else
              >
                <v-row
                  style="
                    max-width: 95%;
                    border: 1px solid #17a2b8;
                    border-radius: 5px;
                  "
                >
                  <v-col cols="1">
                    <v-img
                      lazy-src="@/assets/helpline.svg"
                      max-height="150"
                      max-width="150"
                      :src="
                        reply.profile_picture
                          ? reply.profile_picture
                          : require(`@/assets/helpline.svg`)
                      "
                    ></v-img>
                  </v-col>
                  <v-col>
                    <p class="black--text text-subtitle-2 text-capitalize">
                      {{ reply.user_name }}
                    </p>
                    <p class="text-justify black--text mb-0">
                      {{ reply.content }}
                    </p>
                  </v-col>
                </v-row>
              </div>
            </div>
          </v-card-text>
          <v-row class="mt-3 pa-4 d-flex flex-column">
            <v-textarea
              outlined
              name="input-7-4"
              color="#17a2b8"
              v-model="new_reply"
            ></v-textarea>
            <v-btn
              color="#17a2b8"
              dark
              width="100"
              @click.prevent="onCreateReply"
              >Reply</v-btn
            >
          </v-row>
        </v-card>
      </v-col>
    </v-col>
  </v-container>
</template>
       


<script>
export default {
  data() {
    return {
      invoiceinfo: {},
      orders: [],
      orderinfo: {},
      bill: [],
      specifications: [],
      addquantitydialog: false,
      companyinfo: {},
      invoicearray: [],
      userdata: {},
      contact: {},
      specifications: [],

      confirmbtn: false,

      menu: false,
      snackbar: false,
      text: "",
      viewoptiondialog: false,
      addquantitydialog: false,
      shop: [],
      Return_status: "Returned",
      warehouse: [],
      subtractWarhouse: [],
      subtractShop: [],
      quantityIndex: -1,
      totalSubtractCountOfWarhouse: 0,
      totalSubtractCountOfShop: 0,
      quantity: 0,
      validEditForm: false,
      totalRemaining: 1,
      specification: {},
      specific: {},
      bigArray: [],
      invoicearray: [],
      value_store: [],
      last_value: [],
      newinvoicearray: [],
      color: "",

      headers: [
        {
          text: "Name",
          sortable: false,
          value: "product_name",
          class: "blue-grey lighten-4 text-body-2 tableheader",
        },
        {
          text: "Color",
          value: "product_color",
          sortable: false,
          class: "blue-grey lighten-4 text-body-2 tableheader",
        },
        {
          text: "Size",
          value: "product_size",
          sortable: false,
          class: "blue-grey lighten-4 text-body-2 tableheader",
        },
        {
          text: " Unit",
          value: "product_unit",
          sortable: false,
          class: "blue-grey lighten-4 text-body-2 tableheader ",
        },

        {
          text: "Quantity ",
          value: "quantity",
          sortable: false,
          class: "blue-grey lighten-4 text-body-2 tableheader",
        },
        {
          text: "Unit Price ",
          value: "unit_price",
          sortable: false,
          class: "blue-grey lighten-4 text-body-2 tableheader",
        },
        // {
        //   text: "Discount ",
        //   value: "total_discount",
        //   sortable: false,
        //   class: "blue-grey lighten-4 text-body-2 ",
        // },
        {
          text: "Amount",
          value: "total_price",
          sortable: false,
          class: "blue-grey lighten-4 text-body-2 tableheader",
        },
        {
          text: "Action",
          value: "actions",
          sortable: false,
          class: "blue-grey lighten-4 text-body-2 tableheader",
        },
      ],

      // Note related data
      note_created: false,
      current_note: {},
      new_reply: "",
      user: {},
    };
  },
  watch: {
    subtractWarhouse: {
      // This will let Vue know to look inside the array
      deep: true,

      // We have to move our method to a handler field
      handler: "totalProductQuantitiyCountOfWarhouse",
    },

    subtractShop: {
      // This will let Vue know to look inside the array
      deep: true,

      // We have to move our method to a handler field
      handler: "totalProductQuantitiyCountOfShop",
    },
    addquantitydialog: function (newValue) {
      this.totalSubtractCountOfWarhouse = "0";
      this.totalSubtractCountOfShop = "0";
      this.subtractWarhouse.length = 0;
      this.subtractShop.length = 0;
    },
  },
  methods: {
    initialize(item) {
      axios
        .get(`Cart/create_invoice/${this.$route.query.id}/`)
        .then((response) => {
          // console.log("this is all data", response.data)
          this.invoiceinfo = response.data.invoice_data;
          this.orderinfo = response.data.order_data;
          this.orders = response.data.order_data.orders;
          this.companyinfo = response.data.company_data;
          this.userdata = response.data.billing_address_data;
          //console.log("table info", this.orders);
          // console.log("this is company data", response.data);
          this.totalRemaining = this.orders.reduce(
            (previous, current) => previous + current.remaining,
            0
          );
          this.companyinfo = response.data.company_data;
        });

      // GET the note from CRM
      axios
        .get(`https://server.support24.com.bd/single_note/${this.$route.query.id}/`)
        .then((response) => {
          if (response.data.success) {
            Object.assign(this.current_note, response.data.note);
            this.note_created = true;
          }
        })
        .catch((err) => {
          // Handle error
          console.log(err);
        });
    },

    // Note reply
    async onCreateReply() {
      if (this.new_reply && this.current_note.id && this.user.user_id) {
        // Make API call to create new reply
        let payload = {
          content: this.new_reply,
          note_id: this.current_note.id,
          user_id: this.user.user_id,
          user_role: this.user.role,
        };

        let response = await axios.post(
          "https://server.support24.com.bd/create_reply",
          payload
        );

        if (response.data.success) {
          // console.log(response.data);
          this.current_note.replies.push(response.data.reply);
          this.new_reply = "";
          setTimeout(() => {
            this.$router.go(this.$router.currentRoute);
          }, 100);
        }
      }
    },

    //   printData() {
    // //   var divToPrint = document.getElementById("dataapp");
    // //   var style = "<style>";
    // //   style = style + "table {width: 100%;font: 17px Calibri;}";
    // //   style =
    // //     style +
    // //     "table, th, td {border: solid 1px #DDD; border-collapse: collapse;";
    // //   style = style + "padding: 2px 3px;text-align: center;}";
    // //   style = style + "</style>";

    // //   var win = window.open("", "", "height=1900,width=1900");
    // //   win.document.write(style); //  add the style.
    // //   win.document.write(divToPrint.outerHTML);
    // //   win.document.close();
    // //   win.print();
    // window.print();
    // },

    // printData() {
    //   var printContents = document.getElementById("dataapp").innerHTML;
    //   var originalContents = document.body.innerHTML;

    //   document.body.innerHTML = printContents;
    //   window.print();

    //   document.body.innerHTML = originalContents;
    // },
    printData() {
      //this.$htmlToPaper("dataapp");
      this.$htmlToPaper("dataapp");
    },

    Return(item) {
      this.quantityIndex = this.orders.findIndex((spec) => spec.id == item.id);

      // Assign clicked specification
      Object.assign(this.specification, item);
      let invoice = {
        id: this.specification.id,
        product_status: "Returned",
      };
      this.invoicearray.push(invoice);

      axios
        .get(
          `productdetails/get_subtracted_value/${item.order_id}/${item.specification_id}`
        )
        .then((response) => {
          if (response.data.success) {
            this.shop = response.data.data[0].shop_house;
            this.warehouse = response.data.data[0].ware_house;
            this.addquantitydialog = true;
          } else {
            this.color = "red";
            this.text = "Something went wrong !";
            this.text = response.data.message;
            this.snackbar = true;
          }
        })
        .catch((err) => {
          this.color = "red";
          this.text = "Something went wrong !";

          this.snackbar = true;
        });
    },

    Damage(item) {
      this.quantityIndex = this.orders.findIndex((spec) => spec.id == item.id);

      // Assign clicked specification
      Object.assign(this.specification, item);
      let invoice = {
        id: this.specification.id,
        product_status: "Damaged",
      };
      this.invoicearray.push(invoice);

      axios
        .get(
          `productdetails/get_subtracted_value/${item.order_id}/${item.specification_id}`
        )
        .then((response) => {
          if (response.data.success) {
            this.shop = response.data.data[0].shop_house;
            this.warehouse = response.data.data[0].ware_house;
            this.addquantitydialog = true;
          } else {
            this.color = "red";
            this.text = "Something went wrong !";
            this.text = response.data.message;
            this.snackbar = true;
          }
        })
        .catch((err) => {
          this.color = "red";
          this.text = "Something went wrong !";

          this.snackbar = true;
        });
    },

    CancelOrder(item) {
      this.quantityIndex = this.orders.findIndex((spec) => spec.id == item.id);

      // Assign clicked specification
      Object.assign(this.specification, item);
      let invoice = {
        id: this.specification.id,
        product_status: "Cancelled",
      };
      this.invoicearray.push(invoice);
      axios
        .get(
          `productdetails/get_subtracted_value/${item.order_id}/${item.specification_id}`
        )
        .then((response) => {
          if (response.data.success) {
            this.shop = response.data.data[0].shop_house;
            this.warehouse = response.data.data[0].ware_house;
            this.addquantitydialog = true;
          } else {
            this.color = "red";
            this.text = "Something went wrong !";
            this.text = response.data.message;
            this.snackbar = true;
          }
        })
        .catch((err) => {
          this.color = "red";
          this.text = "Something went wrong !";

          this.snackbar = true;
        });
    },

    // Total subtracted value count for each warhouse
    totalProductQuantitiyCountOfWarhouse() {
      this.totalSubtractCountOfWarhouse = 0;

      for (let n = 0; n < this.subtractWarhouse.length; n++) {
        this.totalSubtractCountOfWarhouse =
          Number(this.totalSubtractCountOfWarhouse) +
          Number(this.subtractWarhouse[n].quantity);
      }
    },

    //  Total subtracted value count for each shop
    totalProductQuantitiyCountOfShop() {
      this.totalSubtractCountOfShop = 0;

      for (let n = 0; n < this.subtractShop.length; n++) {
        this.totalSubtractCountOfShop =
          Number(this.totalSubtractCountOfShop) +
          Number(this.subtractShop[n].quantity);
      }
    },

    //  Push new warhouse to array to track selected warhouse
    warhouseSubtract(item) {
      //check array length for new item or not
      if (this.subtractWarhouse.length > 0) {
        let index = this.subtractWarhouse.findIndex(
          (warhouse) => warhouse.warehouse_id == item.warehouse_id
        );
        if (index != -1) {
          this.subtractWarhouse[index].quantity = item.quantity;
        } else {
          this.subtractWarhouse.push(item);
        }
      } else {
        this.subtractWarhouse.push(item);
      }
    },

    //  Push new shop to array to track selected shop
    shopSubtract(item) {
      if (this.subtractShop.length > 0) {
        let index = this.subtractShop.findIndex(
          (warhouse) => warhouse.shop_id == item.shop_id
        );
        if (index != -1) {
          this.subtractShop[index].quantity = item.quantity;
        } else {
          this.subtractShop.push(item);
        }
      } else {
        this.subtractShop.push(item);
      }
    },
    // This function collect store info in value_store array
    confirmquantity(item) {
      let stock = {
        specification_id: this.specification.specification_id,
        product_id: this.specification.product_id,
        warehouse: this.subtractWarhouse,
        shop: this.subtractShop,
      };
      this.value_store.push(stock);
      this.addquantitydialog = false;
      this.color = "primary";
      this.text = "Quantity Update Successfully!";
      this.snackbar = true;
      this.confirmbtn = true;

      this.orders = this.orders.filter(
        (contact) => contact.id != this.specification.id
      );
    },
    warehouseAdd(item) {
      //comment check array length for new item or not
      if (this.addedwarehousequantity.length > 0) {
        let index = this.addedwarehousequantity.findIndex(
          (warhouse) => warhouse.warehouse_id == item.warehouse_id
        );

        if (index != -1) {
          this.addedwarehousequantity[index].quantity = item.quantity;
        } else {
          this.addedwarehousequantity.push(item);
        }
      } else {
        this.addedwarehousequantity.push(item);
      }
    },

    shopAdd(item) {
      if (this.addedshopquantity.length > 0) {
        let index = this.addedshopquantity.findIndex(
          (shop) => shop.shop_id == item.shop_id
        );
        if (index != -1) {
          this.addedshopquantity[index].quantity = item.quantity;
        } else {
          this.addedshopquantity.push(item);
        }
      } else {
        this.addedshopquantity.push(item);
      }
    },
    assignViewedItem(item) {
      Object.assign(this.specification, item);
      this.dialog = true;
    },
    AllConfirm() {
      let dataarray = {
        invoice: this.invoicearray,
        stock: this.value_store,
      };

      axios
        .post(`Cart/edit_invoice/${this.invoiceinfo.id}/`, dataarray)
        .then((response) => {
          if (response.data.success) {
            this.color = "primary";
            //this.text = "Something went wrong !";
            // this.text = "New Order Invoice Created Successfully!";
            this.text = response.data.message;
            this.snackbar = true;
            this.$router.push({
              path: "/dashboard/pages/order",
            });
          } else {
            this.text = response.data.message;
            this.snackbar = true;
          }
        });
    },
  },
  mounted() {
    this.initialize();
    // GET user information from storage
    this.user = JSON.parse(localStorage.getItem("user"));
  },
};
</script>

<style scoped>
.tableheader {
  max-height: 32px !important;
}
/* tbody tr:nth-of-type(even) {
  background-color: #e3f2fd !important;
} */
/* .v-data-table > .v-data-table__wrapper > table > tbody > tr :nth-child(even){
 
  background-color: #ff7743;
 
}
table.v-table thead tr{
 max-height:1px !important;
} */
/* 
table.v-table thead tr:nth-child(even) {
  background-color: #dddddd;
} */
/* .invoice{
  background: #f5f5f5; 
  font-size: 10em
}
.invoice .theme--light.v-table{
  background-color: #00f !important;
} */
</style>